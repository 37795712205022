import { api } from "../app/api";
import { IUser } from "./user";

export interface IBuilding {
    id?: number;
    name?: string;
    entity?: number,
    country?: string;
    county?: string;
    city?: string;
    postcode?: string;
    street?: string;
    street_number?: string;
    block?: string;
    map_code?: string,

    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

interface IBuildingResponse {
    count: number;
    next: any;
    previous: any;
    results: IBuilding[];
}

interface IBuildingRead extends Omit<IBuilding, 'address' | 'entity'>{
    entity: {id: number},
    address: {id: number}
}

export const buildingApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBuildings: build.query<IBuildingResponse, {
            entity?: number,
            name?: string,
            ordering?: string,
            page?: number,
            page_size?: number
        }>({
            query: (params) => ({
                url: `/entity/admin/buildings/`,
                params: {
                    entity: params.entity,
                    page: params.page,
                    page_size: params.page_size
                }
            }),
            providesTags: (result, error, tag) => [{ type: "Building", tag }]
        }),
        createBuilding: build.mutation<any, IBuilding>({
            query: (payload) => ({
                url: "/entity/admin/buildings/",
                method: "POST",
                body: payload
            }),
            invalidatesTags: [{ type: "Building" }]
        }),
        updateBuilding: build.mutation<any, IBuilding & { id: number }>({
            query: (payload) => ({
                url: `/entity/admin/buildings/${payload.id}/`,
                method: "PATCH",
                body: payload
            }),
            invalidatesTags: (result) => (result ? [{ type: "Building" }] : ['ERROR'])
        }),
        readBuilding: build.query<any, { id: number }>({
            query: (params) => ({
                url: `/entity/admin/buildings/${params.id}/`,
                method: "GET"
            }),
            providesTags: (result, error, tag) => [{ type: "Building", tag }],
        }),
        removeBuilding: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/entity/admin/buildings/${payload.id}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{ type: "Building" }]
        })
    })
});

export const {
    useCreateBuildingMutation,
    useUpdateBuildingMutation,
    useRemoveBuildingMutation,
    useReadBuildingQuery,
    useGetBuildingsQuery,
    useLazyReadBuildingQuery
} = buildingApi;
