import {api} from "../app/api";
import {ISeat} from "../pages/sales/sales.slice";
import {IUser} from "./user";
import {IZone} from "./zone";
import {IBooking} from "./booking";

type Modify<T, R> = Omit<T, keyof R> & R;

export interface IEvent {
    id?: number;
    building?: { id: number, name: string };
    hall?: { id: number, name: string };
    name?: string;
    start?: Date;
    scheme?: number;
    owner?: number;
    qrcode?: number;
    image?: string,
    portrait_image?: string,
    video?: string,

    max_receipt_quantity?: number;
    max_booking_quantity?: number;
    max_booking_duration?: number;
    min_refund_time?: number;
    max_user_booking_duration?: number;
    max_receipt_time?: number;

    max_guest_booking_duration?: number;
    max_user_booking_count?: number;
    min_user_booking_time?: number;

    allow_internet_sales?: boolean;
    sales_blocked?: boolean;

    type?: string;
    ages?: number[];
    //audit
    author?: IUser;
    modifier?: IUser;
    modified?: Date;
    created?: Date,
}

export interface IEventRead extends Modify<IEvent, {
    ages?: number
    slider?: boolean,
}> {
}

export interface IEventShort {
    id: number,
    name: string,
    start: string,
    finish: string
}

export interface ITicket {
    id: number,
    key: string,
    zone: IZone,
    amount: string,
    customer: IUser,
    event: IEventShort,
    sale_document: {
        id: number,
        type: string
    },
    sale: {
        seat: number,
        amount: string,
        payment_type: 'cash' | 'card' | 'bank_transfer' | 'internet',
        source: string,
        status: string,
        type: string,
    },
    booking: IBooking,
    qrcode: string,
    pdf: string,
    seat: {
        id: number,
        key: string,
        row: {
            id: number,
            number: number
        },
        hall_part: {
            id: number,
            name: string,
        },
        zone: IZone

    },
    created: string,
    modified: string,
    status: "free" | "reserved" | "sold" | "disabled",
}

interface IQueryResponse {
    count: number;
    next: any;
    previous: any;
    results: IEvent[];
}

interface IQueryParams {
    hall?: number;
    owner?: number;
    name?: string;
    start_gte?: string;
    finish_lte?: string;
    ordering?: string;
    cashier_accessible?: boolean,
    page?: number;
    page_size?: number;
}

export interface IValidateTicketResponse {
    id: number,
    key: string,
    zone: IZone,
    amount: number,
    customer: IUser,
    event: IEvent,
    sale_document: { id: number, type: string },
    booking: { id: number, status: string, description: string },
    qrcode: string,
    pdf: string,
    seat: ISeat,
    status: string,
    created: string,
    modified: string,
}

export const eventApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEvents: build.query<IQueryResponse, IQueryParams>({
            query: (params) => ({
                url: `/event/admin/events/`,
                params: {
                    page: params.page,
                    page_size: params.page_size,
                    owner: params.owner,
                    ordering: params.ordering,
                    start_gte: params.start_gte,
                    finish_lte: params.finish_lte,
                    cashier_accessible: params.cashier_accessible
                }
            }),
            providesTags: ["Event"]
        }),
        createEvent: build.mutation<any, any>({
            query: (payload) => ({
                url: "/event/admin/events/",
                method: "POST",
                body: {
                    ...payload,
                    ages: payload.ages ? [payload.ages, null] : undefined
                }
            }),
            invalidatesTags: [{type: "Event"}]
        }),
        updateEvent: build.mutation<any, any>({
            query: (payload) => ({
                url: `/event/admin/events/${payload.id}/`,
                method: "PATCH",
                body: {
                    ...payload,
                    ages: payload.ages ? [Number(payload.ages), null] : undefined
                }
            }),
            invalidatesTags: [{type: "Event"}]
        }),
        readEvent: build.query<IEventRead, { id: number }>({
            query: (params) => ({
                url: `/event/admin/events/${params.id}`
            }),
            providesTags: ["Event"],
            transformResponse: (response: any) => ({
                ...response,
                ages: response.ages?.[0]
            })
        }),
        removeEvent: build.mutation<any, any>({
            query: (payload) => ({
                url: `/event/admin/events/${payload}/`,
                method: "DELETE"
            }),
            invalidatesTags: [{type: "Event"}]
        }),
        reserveTickets: build.mutation<any, {
            items: { seat: number }[],
            customer?: number,
            description: string,
            event: number,
            booking: number,
            mode: "admin" | "staff"
        }>({
            query: (payload) => ({
                url: `/event/admin/bookings/reserve/`,
                method: "POST",
                body: {
                    items: payload.items,
                    customer: payload.customer,
                    description: payload.description,
                    booking: payload.booking,
                    event: payload.event,
                    mode: payload.mode
                }
            }),
            invalidatesTags: ["Scheme", "Booking"]
        }),
        cancelReserve: build.mutation<any, {
            booking: number,
            onlyTickets?: number[]
        }>({
            query: (payload) => ({
                url: `/event/admin/bookings/cancel-reserve/`,
                method: "POST",
                body: {
                    booking: payload.booking,
                    only_tickets: payload.onlyTickets
                }
            }),
            invalidatesTags: ["Scheme", "Booking"]
        }),
        blockSales: build.mutation<any, { id: number, sales_blocked: boolean }>({
            query: (payload) => ({
                url: `/event/admin/events/${payload.id}/block-sales/`,
                method: "POST",
                body: {
                    sales_blocked: payload.sales_blocked
                }
            }),
            invalidatesTags: ["Event"]
        }),
        renewCache: build.mutation<any, { id: number }>({
            query: (payload) => ({
                url: `/event/admin/events/${payload.id}/renew-cache/`,
                method: "POST"
            }),
            invalidatesTags: ["Scheme", "Booking"]
        }),
        validateTicket: build.mutation<IValidateTicketResponse, { event: number, token: string }>({
            query: (payload) => ({
                url: `/event/admin/tickets/validate/`,
                method: "POST",
                body: {
                    event: payload.event,
                    token: payload.token
                }
            }),
            invalidatesTags: ["Booking"]
        }),
        getTickets: build.query<{ results: ITicket[] }, { booking?: number, token?: string }>({
            query: (params) => ({
                url: `/event/admin/tickets/`,
                params: {
                    booking: params.booking,
                    token: params.token
                }
            }),
            providesTags: ["Tickets"]
        }),
        readTicket: build.query<ITicket, { id: number }>({
            query: (params) => ({
                url: `/event/admin/tickets/${params.id}`
            }),
            providesTags: ["Event"]
        }),
    })
});

export const {
    useCreateEventMutation,
    useUpdateEventMutation,
    useRemoveEventMutation,
    useReadEventQuery,
    useLazyReadEventQuery,
    useGetEventsQuery,
    useReserveTicketsMutation,
    useCancelReserveMutation,
    useBlockSalesMutation,
    useRenewCacheMutation,
    useValidateTicketMutation,
    useReadTicketQuery,
    useLazyReadTicketQuery,
    useGetTicketsQuery,
    useLazyGetTicketsQuery
} = eventApi;
